import { KcPage } from '@kc.gen';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './main.css';

// Мок страницы для локальной отладки через `npm run dev`.
if (import.meta.env.DEV) {
  const { getKcContextMock } = await import('./keycloak-theme/login/pages/stories/createPageStory');
  window.kcContext = getKcContextMock({
    pageId: 'login.ftl',
    // pageId: 'login-verify-email-code.ftl',
    // pageId: 'login-update-profile.ftl',
    // pageId: 'login-update-password.ftl',
    // pageId: 'login-reset-password.ftl',
    // pageId: 'reset-credentials-email-code.ftl',
    // pageId: 'error.ftl',
    // pageId: 'info.ftl',
    // pageId: 'login-page-expired.ftl',
    // pageId: 'logout-confirm.ftl',

    overrides: {
      locale: {
        currentLanguageTag: 'ru',
      },
      themeName: 'platform-id-2gis',
      messagesPerField: {
        get: (_field: string) => '',
      },
    },
  });
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    {!window.kcContext ? (
      <h1 className="text-white100">
        {
          'Приложение запущено не в Keycloak. Вероятно, ты забыл мокнуть kcContext. См ./src/main.tsx'
        }
      </h1>
    ) : (
      <KcPage kcContext={window.kcContext} />
    )}
  </StrictMode>,
);
